import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dashbot believes that data is one of the most valuable assets when it comes to your chatbot or voice app. As the leading conversational analytics platform, we aim to provide insights to every facet of the user journey, from start to finish. We’re launching the User Satisfaction Report to aid in this effort.`}</p>
    <p>{`One of the many benefits of a conversational interface is that it provides a seamless way to request feedback. As the user engages with the chatbot or voice app, a question about satisfaction can be asked on the same interface without taking the user to another window. Dashbot developed this report to take advantage of the higher response rate of this more frictionless environment.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/10/03093921/USAT-Summary-CSAT-e1570120815891-1024x742.png",
        "alt": null
      }}></img><figcaption>{`User Satisfaction Report showing CSAT score`}</figcaption>{` Dashbot’s User Satisfaction Report communicates the numbers in an easily digestible way with at-a-glance data visualizations and concise breakdowns of the percentage of satisfied users. This report is also built to investigate the cause behind every answer group. Curious about which Intent led to someone’s positive– or even negative– answer? Not only does Dashbot provides the preceding Intent that led to each user’s rating, but we also provide the transcripts so our customers can really dig into the conversations to find the root cause.`}</p>
    <p>{`Collecting user feedback is hands-down one of the best ways to gauge chatbot performance. Combined with our unique conversational analytics that allow you to dive into every conversation, the User Satisfaction Report is rich with insights that can be used to optimize the customer experience.`}</p>
    <p>{`Ready to learn what factors in customers’ conversations influenced their rating? The User Satisfaction Report is part of the Enterprise package, so contact us to discuss how you can start using this feature.`}</p>
    <div aria-hidden="true" className="wp-block-spacer" style={{
      "height": "33px"
    }}></div>[Contact
us for a demo](https://www.dashbot.io/contact/sales?ref=)
    <h1>{`About Dashbot`}</h1>
    <p><em parentName="p">{`Dashbot is an analytics platform for conversational interfaces that enables enterprises to increase satisfaction, engagement, and conversions through actionable insights and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support DialogFlow, Alexa, Google Assistant, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      